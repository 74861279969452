import 'dayjs/locale/pt-br';
import domtoimage from 'dom-to-image';
import { inPlaceSort } from 'fast-sort';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiWindow, BiWindows } from 'react-icons/bi';
import 'react-tabs/style/react-tabs.css';
import { DefaultButton } from '../../../components/Button/Button';
import PieceEdit from '../../../components/PieceEdit/PieceEdit';
import { supabase } from '../../../supabaseClient';
import { BodyShopAddScreen } from '../../BudgetAdd/BodyShopAddScreen';
import BudgetAddAeServiceScreen from '../../BudgetAddAeService/BudgetAddAeServiceScreen';
import BudgetAddClient from '../../BudgetAddClient/BudgetAddClient';
import BudgetAddVehicle from '../../BudgetAddVehicle/BudgetAddVehicle';
import { BudgetTabs } from '../../ModalBudget/components/TabsContent';
import PieceAddScreen from '../../PieceAdd/PieceAddScreen';
import jsPDF from 'jspdf';
import { getTotalSum } from '../../ModalBudget/utils/functions';
('pt-br');

const ModalBudget = ({
  values,
  closeModal,
  isNew,
  onChange,
  onSave,
  compareValues,
  onRemove,
  budget,
  showBudgetItems,
  open,
  setOpen,
  addBudget,
  client,
  vehicle,
  bodyshopService,
  setBodyshopService,
  aeService,
  setAeService,
  part,
  setPart,
  parts,
  setParts,
  onRemovePiece,
  onRemoveService,
  onRemoveAeService,
  setReload,
  hvTable,
  setAddBudget,
  sendWhatsapp,
  setIsntLinearHour,
  isntLinearHour,
  serviceType,
  setServiceType,
  addedParts,
  addedServices,
  paymentOptions,
  createOption,
  changeValue,
  changePartsValue,
  reloadPrint,
  company,
  isOpen,
  setIsOpen,
  openEditModal,
  onChangePiece,
  printCompany,
  deleteOptions,
  createOrigin,
  divCreatable,
  menuOpen,
  creatableOpen,
  changeValueAeService,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentClientId, setCurrentClientId] = useState(0);
  const [currentVehicleId, setCurrentVehicleId] = useState(0);

  const totalSum = useMemo(
    () => getTotalSum(bodyshopService, parts, aeService),
    [bodyshopService, parts, aeService]
  );
  const totalBodyshopService = useMemo(
    () => getTotalSum(bodyshopService, [], []),
    [bodyshopService]
  );
  const totalParts = useMemo(() => getTotalSum([], parts, []), [parts]);
  const totalAeService = useMemo(
    () => getTotalSum([], [], aeService),
    [aeService]
  );

  const nodeInBlob = async () => {
    return new Promise((resolve) => {
      // const printWindow = window.open("", "_blank");
      // printWindow.document.write(ref.current.outerHTML);
      // printWindow.focus();
      setTimeout(() => {
        // const blob = new Blob([ref.current.outerHTML], {
        //   type: "application/pdf",
        // });
        var node = document.getElementById('my-node');
        domtoimage.toPng(node).then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          resolve(img);
        });
      }, 2000);
    });
  };

  const generatePdfBlob = async () => {
    const image = await nodeInBlob();

    return new Promise((resolve) => {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4',
      });
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgProps = pdf.getImageProperties(image);
      const imgHeight =
        (imgProps.height * pdf.internal.pageSize.getWidth()) / imgProps.width;

      let yPosition = 0;

      while (yPosition < imgHeight) {
        if (yPosition > 0) {
          pdf.addPage();
        }
        pdf.addImage(
          image,
          'PNG',
          0,
          -yPosition,
          pdf.internal.pageSize.getWidth(),
          imgHeight
        );
        yPosition += pageHeight;
      }

      const pdfBlob = pdf.output('blob');
      resolve(pdfBlob);
    });
  };

  async function generateAndSharePDF() {
    const element = document.getElementById("print-padding-mobile");

    if (!element) {
      console.error("Elemento com ID 'print-padding-mobile' não encontrado no DOM.");
      return;
    }

    try {
      const imgData = await domtoimage.toPng(element);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [element.offsetWidth, element.offsetHeight],
      });
      pdf.addImage(imgData, "PNG", 0, 0, element.offsetWidth, element.offsetHeight);
      const pdfBlob = pdf.output("blob");

      if (navigator.share) {
        await navigator.share({
          title: "Compartilhar PDF",
          files: [
            new File([pdfBlob], "documento.pdf", {
              type: "application/pdf",
            }),
          ],
        });
        console.log("PDF compartilhado com sucesso!");
      } else {
        console.error("A API navigator.share não é suportada.");
      }
    } catch (error) {
      console.error("Erro ao gerar ou compartilhar o PDF:", error);
    }
  }

  const convertImageToBlob = (image) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      ctx.drawImage(image, 0, 0);
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/png');
    });
  };

  const toShare = async () => {
    try {
      setIsLoading(true);
      const sanitizedClientName = values.client_name.replace(/[\s\\/]/g, '_');
      const sanitizedVehicleName = values.vehicle_name.replace(/[\s\\/]/g, '_');
      const newTitle = `Orcamento_${values.code}_${sanitizedClientName}_${sanitizedVehicleName}.pdf`;

      generateAndSharePDF()
      //const pdfBlob = await generatePdfBlob();


      // const imageBlob = await convertImageToBlob(image);

      const { data, error } = await supabase.storage
        .from('BudgetServiceOrder')
        .upload(newTitle, pdfBlob, {
          contentType: 'application/pdf',
          upsert: true,
        });

      if (error) {
        console.error('Erro ao enviar o PDF ao Supabase:', error);
        return;
      } else {
        console.log('PDF enviado com sucesso.', data.Key);
      }

      const { publicURL, error: urlError } = supabase.storage
        .from('BudgetServiceOrder')
        .getPublicUrl(newTitle);

      if (urlError) {
        console.error('Erro ao obter a URL pública do PDF:', urlError);
        return;
      }

      const shareData = {
        title: newTitle,
        text: 'Aqui está o orçamento que você solicitou.',
        files: [
          new File([pdfBlob], `${newTitle}`, { type: 'application/pdf' }),
        ],
      };

      if ('canShare' in navigator && false) {
        // await Swal.fire({
        //   title: "Você deseja compartilhar em PDF ou em PNG?",
        //   showConfirmButton: true,
        //   showDenyButton: true,
        //   icon: "warning",
        //   confirmButtonText: `PDF`,
        //   denyButtonText: "PNG",
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     navigator.share(shareData);
        //   } else if (result.isDenied) {
        //     navigator.share({
        //       title: `Orçamento ${values.code}`,
        //       files: [
        //         new File([imageBlob], `Orcamento_${values.code}.png`, {
        //           type: "image/png",
        //         }),
        //       ],
        //     });
        //   }
        // });
        await navigator.share(shareData);
        console.log('Orçamento compartilhado com sucesso.');
      } else {
        console.error(
          'O módulo de compartilhar não é suportado neste navegador, tente outro.'
        );
      }
    } catch (error) {
      console.error('Erro ao compartilhar:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortedPaymentOptions = paymentOptions.map((item) => ({
    value: item.payment_type.value,
    label: item.payment_type.label,
    id: item.id,
  }));

  inPlaceSort(sortedPaymentOptions).asc([
    (item) => item.label.replace(/[^a-zA-Z]/g, '').toLowerCase(),
    (item) => {
      const numberMatch = item.label.match(/\d+/);
      return numberMatch ? parseInt(numberMatch[0], 10) : 0;
    },
  ]);

  useEffect(() => {
    const prosperaModal = document.querySelector('.prospera-modal');
    const modalContent = document.querySelector('.modal-content');
    const mainModal = document.querySelector('.main-content-modal1');
    const modalWrapper = document.querySelector('.modal-content-wrapper');
    // const textArea = document.querySelector('.text-observation-min');
    // const textArea1 = document.querySelector('.text-observation-min2');
    const serviceAdditionalArea = document.querySelector(
      '.service-additional-on'
    );
    const pieceGridtable = document.querySelector('.piece-gridtable');
    const serviceGridtable = document.querySelector('.service-gridtable');
    if (modalContent) {
      modalContent.style.backgroundColor = isFullscreen ? '#4b4b4d' : '#fff';
    }

    if (prosperaModal) {
      prosperaModal.classList.toggle('expanded', !isFullscreen);
      prosperaModal.classList.toggle('prospera-modal-fullscreen', isFullscreen);

      mainModal.classList.toggle('main-content-modal2', isFullscreen);
      modalWrapper.classList.toggle('modal-content-wrapper2', isFullscreen);
    }
    if (serviceAdditionalArea) {
      serviceAdditionalArea.classList.toggle(
        'service-additional-off',
        isFullscreen
      );
    }
    if (pieceGridtable) {
      pieceGridtable.classList.toggle('fullscreen-gridtable', isFullscreen);
    }
    if (serviceGridtable) {
      serviceGridtable.classList.toggle('fullscreen-gridtable', isFullscreen);
    }
  }, [isFullscreen]);

  useEffect(() => {
    const modal = document.querySelector('.modal');
    if (modal) {
      if (isLoading) {
        modal.classList.add('loading');
      } else {
        modal.classList.remove('loading');
      }
    }
  }, [isLoading]);

  inPlaceSort(bodyshopService).by('title');
  inPlaceSort(parts).by('part');

  return (
    <>
      <div className={''}>
        {/* {`prospera-modal ${fullscreen ? 'prospera-modal-fullscreen' : 'prospera-modal-fullscreen'}`} */}
        <div className={'modal-container'}>
          <div className="modal-header">
            <div className="header-title">
              <h4 className="modal-title">
                {isNew ? 'Adicionar Ordem de Serviço' : 'Editar Ordem de Serviço'}
              </h4>
            </div>
            <div className="header-buttons">
              {compareValues ? (
                <DefaultButton
                  className="modal-footer-button"
                  label={isMobile ? '' : 'Salvar'}
                  icon={<AiOutlineCheck className="button-icon" />}
                  onClick={onSave}
                />
              ) : (
                <div />
              )}
              {isMobile ? (
                <div />
              ) : (
                <DefaultButton
                  className="modal-footer-button cancel"
                  onClick={() => {
                    setIsFullscreen(!isFullscreen);
                    showBudgetItems(1);
                  }}
                  icon={!isFullscreen ? <BiWindow /> : <BiWindows />}
                />
              )}
              <DefaultButton
                className="modal-footer-button cancel"
                label={isMobile ? '' : 'Cancelar'}
                icon={<AiOutlineClose className="button-icon-cancel" />}
                onClick={closeModal}
              />
            </div>
          </div>

          <BudgetTabs
            budget={budget}
            showBudgetItems={showBudgetItems}
            onChange={onChange}
            client={client}
            values={values}
            setOpen={setOpen}
            vehicle={vehicle}
            menuOpen={menuOpen}
            creatableOpen={creatableOpen}
            createOption={createOption}
            divCreatable={divCreatable}
            deleteOptions={deleteOptions}
            paymentOptions={paymentOptions}
            company={company}
            isFullscreen={isFullscreen}
            isntLinearHour={isntLinearHour}
            hvTable={hvTable}
            setIsntLinearHour={setIsntLinearHour}
            bodyshopService={bodyshopService}
            onRemoveService={onRemoveService}
            changeValue={changeValue}
            onRemovePiece={onRemovePiece}
            changePartsValue={changePartsValue}
            openEditModal={openEditModal}
            parts={parts}
            isOpen={isOpen}
            onRemoveAeService={onRemoveAeService}
            aeService={aeService}
            compareValues={compareValues}
            sendWhatsapp={sendWhatsapp}
            toShare={toShare}
            isLoading={isLoading}
            onRemove={onRemove}
            onSave={onSave}
            totalParts={totalParts}
            totalSum={totalSum}
            totalBodyshopService={totalBodyshopService}
            totalAeService={totalAeService}
            changeValueAeService={changeValueAeService}
            reloadPrint={reloadPrint}
            printCompany={printCompany}
            setCurrentClientId={setCurrentClientId}
            setCurrentVehicleId={setCurrentVehicleId}
          />
        </div>
      </div>

      <BodyShopAddScreen
        compareValues={compareValues}
        service={bodyshopService}
        addedServices={addedServices}
        serviceType={serviceType}
        setServiceType={setServiceType}
        showBudgetItems={showBudgetItems}
        budget={addBudget}
        setAddBudget={setAddBudget}
        isOpen={open === 1}
        values={values}
        isntLinearHour={isntLinearHour}
        setValues={(newValues) => {
          const updatedValues = Array.isArray(newValues)
            ? newValues
            : [newValues];
          const filteredValues = updatedValues.filter(
            (value) => value && value.title
          );

          if (filteredValues.length > 0) {
            setBodyshopService((service) => [...service, ...filteredValues]);
          }
        }}
        setOpen={setOpen}
      />
      <PieceAddScreen
        addedParts={addedParts}
        showBudgetItems={showBudgetItems}
        budget={addBudget}
        isOpen={open === 2}
        partsArray={parts}
        setValues={(values) => setParts((part) => [...part, ...values])}
        setOpen={setOpen}
      />
      <BudgetAddClient
        showBudgetItems={showBudgetItems}
        budget={addBudget}
        isOpen={open === 3}
        setReload={setReload}
        setOpen={setOpen}
        createOrigin={createOrigin}
        values={values}
        currentClientId={currentClientId}
      />
      <BudgetAddVehicle
        showBudgetItems={showBudgetItems}
        budget={addBudget}
        isOpen={open === 4}
        setOpen={setOpen}
        setReload={setReload}
        defaultValues={values}
        currentVehicleId={currentVehicleId}
      />

      <BudgetAddAeServiceScreen
        compareValues={compareValues}
        service={aeService}
        addedServices={addedServices}
        serviceType={serviceType}
        setServiceType={setServiceType}
        showBudgetItems={showBudgetItems}
        budget={addBudget}
        setAddBudget={setAddBudget}
        isOpen={open === 5}
        values={values}
        isntLinearHour={isntLinearHour}
        setValues={(newValues) => {
          const updatedValues = Array.isArray(newValues)
            ? newValues
            : [newValues];
          const filteredValues = updatedValues.filter(
            (value) => value && value.title
          );

          if (filteredValues.length > 0) {
            setAeService((service) => [...service, ...filteredValues]);
          }
        }}
        setOpen={setOpen}
      />

      <PieceEdit
        isOpen={isOpen}
        setOpen={setIsOpen}
        parts={parts}
        setParts={setParts}
        part={part}
        setPart={setPart}
        values={values}
        onChangePiece={onChangePiece}
      />
    </>
  );
};

export default ModalBudget;
