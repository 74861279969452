import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { setData } from '../../core/redux/app/Actions';
import { supabase } from '../../supabaseClient';
import { compareValues } from '../ModalClients/ModalClientsContainer';
import Swal from 'sweetalert2';
import { alertError, alertSuccess } from '../../components/Toast';
import ModalAddParts from '../../screens/ModalAddParts/ModalAddParts';
import { savePart } from '../../core/utils/parts';

const ModalAddPartsContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = location.pathname.split('/')[3];
  const params = useParams();
  const isNew = params.id === '0';
  const reloadAddParts = useSelector(
    (state) => state.app.reloadAddParts || false
  );
  const company = useSelector((state) => state.app.company || {});
  const user = useSelector((state) => state.app.user || {});
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});

  const loadData = async () => {
    let { data, error } = await supabase
      .from('Part')
      .select('*')
      .eq('id', id)
      .is('deleted_at', null);

    if (!error) {
      const { images, part, ...rest } = data[0];
      setValues({
        ...rest,
        part: [part],
        images: images ? JSON.parse(images) : [],
      });
      setOldValues({
        ...rest,
        part: [part],
        images: images ? JSON.parse(images) : [],
      });
    }
  };

  useEffect(() => {
    if (id !== '0') {
      loadData();
    }
  }, [id]);

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: 'Deseja sair sem salvar?',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: 'warning',
        text: 'Ao sair, seus dados serão descartados.',
        denyButtonText: `Sair`,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isDenied) {
          history.push('/parts');
        }
      });
    } else {
      history.push('/parts');
    }
  };

  const createOption = async (name, field) => {
    setValues((values) => ({
      ...values,
      [field]: { label: name, value: name },
    }));
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onSave = async () => {
    try {
      const extraProps = {};
      if (values && !values.name) {
        alertError('O nome é obrigatório!');
        return;
      }
      if (values && values.images) {
        extraProps['images'] = JSON.stringify(values.images);
      }
      const save = await savePart(values.id, {
        company: company.id,
        ...values,
        ...extraProps,
      });
      alertSuccess('Sucesso ao salvar a peça!');
      history.push('/parts');
      dispatch(setData({ reloadAddParts: !reloadAddParts }));
    } catch (error) {
      alertError('Erro ao salvar a lista, tente novamente mais tarde.');
    }
  };

  const onRemove = async () => {
    const part = values?.part[0];
    const { count, error } = await supabase
      .from('Services')
      .select('*', { count: 'exact', head: true })
      .eq('parts', part)
      .eq('id_company', company.id)
      .is('deleted_at', null);

    if (count > 0 || error) {
      alertError(
        'Esta peça não pode ser excluída, pois está sendo utilizada por serviços ativos.'
      );
      return;
    } else {
      const confirmation = await Swal.fire({
        title: 'Deseja mesmo excluir?',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: 'warning',
        text: 'Seus dados serão completamente perdidos!',
        denyButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      });

      if (confirmation.isDenied) {
        if (values && values.id) {
          const { data, error } = await supabase
            .from('Part')
            .update({ deleted_at: new Date(), deleted_by: user.id })
            .eq('id', values.id);

          if (!error) {
            alertSuccess('Removido com sucesso!');
            history.push('/parts');
            dispatch(setData({ reloadAddParts: !reloadAddParts }));
          } else {
            alertError('Erro ao remover, tente novamente mais tarde');
          }
        } else {
          alertError('Erro ao remover, tente novamente mais tarde');
        }
      } else {
        history.push('/parts');
      }
    }
  };

  return (
    <ModalAddParts
      compareValues={compareValues(oldValues, values)}
      closeModal={closeModal}
      onChange={onChange}
      values={values}
      isNew={isNew}
      setValues={setValues}
      onSave={onSave}
      onRemove={onRemove}
      createOption={createOption}
    />
  );
};

export default ModalAddPartsContainer;
