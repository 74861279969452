import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { alertError, alertSuccess } from '../../components/Toast';
import { setData } from '../../core/redux/app/Actions';
import { loadOrigins, saveClient, saveOrigin } from '../../core/utils/client';
import ModalClients from '../../screens/ModalClients/ModalClients';
import { supabase } from '../../supabaseClient';
import { validateEmail } from '../../core/utils/string';
import { swalRemove } from '../../components/Swal/Swal';
import Swal from 'sweetalert2';
import _ from 'lodash';
import moment from 'moment';

const ModalClientsContainer = ({ navigation }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const id = location.pathname.split('/')[3];
  const isNew = params.id === '0';
  const reloadClient = useSelector((state) => state.app.reloadClient || false);
  const company = useSelector((state) => state.app.company || {});
  const [values, setValues] = useState({
    person: { value: 1, label: 'Pessoa fisica', field: 'physical' },
  });
  const [oldValues, setOldValues] = useState({
    person: { value: 1, label: 'Pessoa fisica', field: 'physical' },
  });
  const user = useSelector((state) => state.app.user || {});
  const [responsible, setResponsible] = useState([]);
  const [origins, setOrigins] = useState([]);

  useEffect(() => {
    if (
      origins &&
      origin.length > 0 &&
      values &&
      values.origin &&
      !values.origin.value
    ) {
      const origin = origins
        .map((item) => ({
          label: item.name,
          value: item.id,
        }))
        .find((item) => item.value === values.origin);
      if (origin) {
        setValues((old) => ({ ...old, origin }));
        setOldValues((old) => ({ ...old, origin }));
      }
    }
  }, [origins, values]);

  const loadData = async () => {
    let { data, error } = await supabase
      .from('Client')
      .select('*')
      .eq('id', id)
      .is('deleted_at', null);

    if (!error) {
      var birthday = null;
      if (data[0].birthday) {
        birthday = moment(data[0].birthday).format('DD/MM/YYYY');
      }
      setValues({ ...data[0], birthday });
      setOldValues({ ...data[0], birthday });
    }
  };

  useEffect(() => {
    if (id !== '0') {
      loadData();
    }
  }, [id]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    closeModal();
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [values]);

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: 'Deseja sair sem salvar?',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: 'warning',
        text: 'Ao sair, seus dados serão descartados.',
        denyButtonText: `Sair`,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isDenied) {
          history.push('/clients');
        }
      });
    } else {
      history.push('/clients');
    }
  };

  useEffect(() => {
    if (
      values &&
      values.cep &&
      values.cep.length >= 9 &&
      /^[0-9]{5}-?[0-9]{3}$/.test(values.cep)
    ) {
      fetch(
        `https://viacep.com.br/ws/${values.cep.replace('-', '')}/json/`
      ).then((response) => {
        response.json().then((data) => {
          const { uf, logradouro, localidade, bairro } = data;
          setValues((values) => ({
            ...values,
            state: uf,
            street: isNew ? logradouro : values.street,
            city: localidade,
            neighborhood: isNew ? bairro : values.neighborhood,
          }));
        });
      });
    }
  }, [values && values.cep]);

  useEffect(() => {
    loadResponsible();
  }, [values.id]);

  useEffect(() => {
    loadCompanyOrigins();
  }, []);

  const loadCompanyOrigins = async () => {
    const origins = await loadOrigins(company.id);
    if (origins && origins.length > 0) {
      setOrigins(origins);
    }
  };

  const loadResponsible = async () => {
    if (values && values.id) {
      let { data, error } = await supabase
        .from('Vehicle')
        .select('*')
        .eq('responsible', values.id)
        .is('deleted_at', null);

      if (!error) {
        setResponsible(data);
      }
    }
  };

  const onChange = (field, value) => {
    if (field === 'cpf') {
      setValues((values) => ({ ...values, [field]: value, cnpj: '' }));
    } else if (field === 'cnpj') {
      setValues((values) => ({ ...values, [field]: value, cpf: '' }));
    } else {
      setValues((values) => ({ ...values, [field]: value }));
    }
  };

  const onSave = async () => {
    try {
      const client = { ...values };
      delete client.Vehicle;
      delete client.Origin;
      if (client && !client.name) {
        alertError('Nome é obrigatório');
        return;
      }

      if (client.email && !validateEmail(client.email)) {
        alertError('E-mail inválido');
        return;
      }

      if (!client.cellphone) {
        alertError('Número de celular inválido');
        return;
      }

      if (client && client.birthday) {
        client.birthday = moment(client.birthday, 'DD/MM/YYYY');
        // .format(
        //   "DD-MM-YYYY"
        // );
      }

      if (client && client.origin) {
        client.origin = client.origin.value;
      }

      const save = await saveClient(client.id, {
        ...client,
        id_company: company.id,
      });
      alertSuccess('Sucesso ao salvar o cliente!');
      history.push('/clients');
      dispatch(setData({ reloadClient: !reloadClient }));
    } catch (error) {
      alertError('Erro ao salvar o cliente, tente novamente mais tarde.');
    }
  };

  const onRemoveVehicle = async (item) => {
    if (item && item.id) {
      const { data, error } = await supabase
        .from('Vehicle')
        .update({ deleted_at: new Date(), deleted_by: user.id })
        .eq('id', item.id);

      if (!error) {
        alertSuccess('Veículo removido com sucesso!');
        setResponsible((data) => data.filter((row) => row.id !== item.id));
      } else {
        alertError('Erro ao remover o veículo, tente novamente mais tarde');
      }
    } else {
      alertError('Erro ao remover o veículo, tente novamente mais tarde');
    }
  };

  const onRemove = async () => {
    const confirmation = await Swal.fire({
      title: 'Deseja mesmo excluir?',
      showCancelButton: true,
      showConfirmButton: false,
      showDenyButton: true,
      icon: 'warning',
      text: 'Seus dados serão completamente perdidos!',
      denyButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    });

    if (confirmation.isDenied) {
      if (values && values.id) {
        const { data, error } = await supabase
          .from('Client')
          .update({ deleted_at: new Date(), deleted_by: user.id })
          .eq('id', values.id);

        if (!error) {
          alertSuccess('Cliente removido com sucesso!');
          history.push('/clients');
          dispatch(setData({ reloadClient: !reloadClient }));
        } else {
          alertError('Erro ao remover o cliente, tente novamente mais tarde');
        }
      } else {
        alertError('Erro ao remover o cliente, tente novamente mais tarde');
      }
    } else {
      history.push('/clients');
    }
  };

  const createOption = async (name) => {
    const response = await saveOrigin({ name, company: company.id });
    if (response && !response.error) {
      setOrigins((origins) => response.data.concat(origins));
      const { id, name } = response.data[0];
      onChange(`origin`, { value: id, label: name });
    }
  };

  return (
    <ModalClients
      compareValues={compareValues(oldValues, values)}
      onRemove={onRemove}
      onRemoveVehicle={(item) =>
        swalRemove({
          withError: false,
          functionConfirmRemove: () => onRemoveVehicle(item),
        })
      }
      closeModal={closeModal}
      onChange={onChange}
      onSave={onSave}
      responsible={responsible}
      values={values}
      isNew={isNew}
      createOption={createOption}
      origins={origins}
    />
  );
};

export default ModalClientsContainer;

const validateCellPhoneNumber = (cellphone) => {
  const regex = /^\(\d{2}\)\s\d{5}\-\d{4}$/;
  return regex.test(cellphone);
};

const clearFields = [
  'name',
  'cellphone',
  'telephone',
  'email',
  'birthday',
  'customerOrigin',
  'cnpj',
  'cpf',
  'cep',
  'street',
  'number',
  'complement',
  'city',
  'state',
];
let timeout;

export function compareValues(objA, objB) {
  if (objA && objB) {
    let aKey = Object.keys(objA),
      bKey = Object.keys(objB);

    if (aKey.length != bKey.length) {
      return true;
    }

    let areEqual = _.isEqual(objA, objB);

    return !areEqual;
  }
}
