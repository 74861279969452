import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { supabase } from "../../../supabaseClient";
import dayjs from "dayjs";
import moment from "moment";
import BudgetScreen from "../../../screens/OrderServiceBudget/Budget/BudgetScreen";

const OrderServiceBudgetContainer = () => {
  const [data, setData] = useState([]);
  const reloadBudget = useSelector((state) => state.app.reloadBudget || false);
  const [filter, setFilter] = useState({});
  const company = useSelector((state) => state.app.company || {});
  const user = useSelector((state) => state.app.user || {});

  useEffect(() => {
    loadData();
  }, [reloadBudget]);

  const loadData = async () => {
    let { data, error } = await supabase
      .from("BudgetServiceOrder")
      .select(
        `
        *,
        Vehicle(responsible, *)
        `
      )
      .eq("id_company", company.id)
      .is("deleted_at", null);

    if (!error) {
      setData(
        data.map((item) => ({
          ...item,
          vehicle_model: item.Vehicle.model,
          created_at: dayjs(item.created_at).format("DD/MM/YYYY"),
        }))
      );
    }
  };

  return (
    <BudgetScreen
      data={filteredData(data, filter)}
      company={company}
      user={user}
      filter={filter}
      setFilter={setFilter}
      // onRemove={item => swalRemove({ withError: false, functionConfirmRemove: () => onRemove(item) })}
    />
  );
};

export default OrderServiceBudgetContainer;

const filteredData = (data, filter) => {
  return data.filter((item) => doFilter(item, filter));
};

const doFilter = (item, filter) => {
  let isSearch = true;
  if (filter && filter.search) {
    isSearch =
      item?.code?.toLowerCase().includes(filter.search.toLowerCase()) ||
      item?.client_name?.toLowerCase().includes(filter.search.toLowerCase()) ||
      item?.Vehicle?.model
        ?.toLowerCase()
        .includes(filter.search.toLowerCase()) ||
      item?.Vehicle?.plate?.toLowerCase().includes(filter.search.toLowerCase());
  }

  let isDates = true;
  if (filter && filter.dates && filter.dates[0] && filter.dates[1]) {
    const startDate = moment(filter.dates[0]);
    const endDate = moment(filter.dates[1]);
    const itemDate = moment(item.created_at, "DD/MM/YYYY");
    isDates =
      moment(itemDate).isBetween(
        moment(startDate),
        moment(endDate).add(23, "h")
      ) ||
      moment(itemDate).isSame(startDate) ||
      moment(itemDate).isSame(endDate);
  }

  let isStatus = true;
  if (filter && filter.status && typeof filter.status.id === "number") {
    isStatus = item.status === filter.status.id;
  }

  let isClientStatus = true;
  if (
    filter &&
    filter.status_client &&
    typeof filter.status_client.id === "number"
  ) {
    isClientStatus = item.status_client === filter.status_client.id;
  }

  return isSearch && isDates && isStatus && isClientStatus;
};
