import React, { useState } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import '../styles.scss';
import { BUDGET_TABS } from '../../utils/enum';
import { columnsService } from '../../utils/constants';
import { LabelWithPlusButton } from '../LabelWithPlusButton';
import { DefaultButton } from '../../../../components/Button/Button';
import { AddAeServicec } from './AddAeService';

export const AestheticsServices = ({
  setOpen,
  onRemoveAeService,
  values,
  changeValueAeService,
  aeService,
  budget,
}) => {
  if (budget !== BUDGET_TABS.AESTHETICS) {
    return;
  }

  const columns = columnsService(
    onRemoveAeService,
    values,
    changeValueAeService,
    budget
  ).map((column, index) => ({
    ...column,
    style: {
      backgroundColor: index % 2 === 0 ? 'gray !important' : 'white !important',
    },
  }));

  return (
    <div className="aesthetics-services-container">
      {/* <div style={{ marginTop: 8, display: 'flex', justifyContent: '' }}>
        <DefaultButton
          className="add-button"
          label={'Adicionar Serviço'}
          onClick={() => setIsModalToCreateServiceOpen(true)}
        />
      </div> */}

      <LabelWithPlusButton
        setOpen={setOpen}
        tabNumber={5}
        label={'Serviços'}
      />
      <div className="bodyshop-body modal-budget modal-budget-gridtable service-gridtable mt-2">
        <GridTable
          columns={columns}
          rows={aeService}
          enableColumnsReorder={false}
          showSearch={false}
          canReorder={false}
          isPaginated={false}
          showColumnVisibilityManager={false}
          showRowsInformation={false}
          texts={{ noResults: 'Nenhum resultado foi encontrado' }}
        />
      </div>
    </div>
  );
};
