import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { setData } from "../../core/redux/app/Actions";
import { supabase } from "../../supabaseClient";
import { compareValues } from "../ModalClients/ModalClientsContainer";
import { saveServiceType } from "../../core/utils/ServiceType";
import Swal from "sweetalert2";
import ModalServiceType from "../../screens/ModalServiceType/ModalServiceType";
import { alertError, alertSuccess } from "../../components/Toast";

const ModalServiceTypeContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const params = useParams();
  const isNew = params.id === "0";
  const reloadServiceType = useSelector(
    (state) => state.app.reloadServiceType || false
  );
  const company = useSelector((state) => state.app.company || {});
  const user = useSelector((state) => state.app.user || {});
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});
  const [subService, setSubService] = useState([]);
  const [oldSubService, setOldSubService] = useState([]);
  const [servicesValues, setServiceValues] = useState({});

  const loadData = async () => {
    let { data, error } = await supabase
      .from("ServiceTypes")
      .select("*")
      .eq("id", id)
      .is("deleted_at", null);

    if (!error) {
      setValues(data[0]);
      setOldValues(data[0]);
      const subService = JSON.parse(data[0].subservice);
      setSubService(subService);
      setOldSubService(subService);
    }
  };

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    dispatch(
      setData({
        [`compareValues_${path}`]:
          compareValues(oldValues, values) ||
          compareValues(oldSubService, subService),
      })
    );
  }, [values, oldValues, oldSubService, subService]);

  useEffect(() => {
    if (id !== "0") {
      loadData();
    }
  }, [id]);

  const addSubService = () => {
    if (
      servicesValues &&
      servicesValues.description &&
      servicesValues.percent
    ) {
      setSubService((values) =>
        values.concat([{ ...servicesValues, id: Math.random() }])
      );
      setServiceValues({ description: "", percent: "" });
    }
  };

  const onDeleteService = (item) => {
    setSubService((values) => values.filter((row) => row.id !== item.id));
  };

  const onRemove = async () => {
    const { count, error } = await supabase
      .from("Services")
      .select("*", { count: "exact", head: true })
      .eq("service_type", values.id)
      .eq("id_company", company.id)
      .is("deleted_at", null);

    if (count > 0 || error) {
      alertError(
        "Este tipo de serviço não pode ser excluído, pois está sendo utilizado por serviços ativos."
      );
      return;
    } else {
      const confirmation = await Swal.fire({
        title: "Deseja mesmo excluir?",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: "warning",
        text: "Seus dados serão completamente perdidos!",
        denyButtonText: "Excluir",
        cancelButtonText: "Cancelar",
      });

      if (confirmation.isDenied) {
        if (values && values.id) {
          const { data, error } = await supabase
            .from("ServiceTypes")
            .update({ deleted_at: new Date(), deleted_by: user.id })
            .eq("id", values.id);

          if (!error) {
            alertSuccess("Removido com sucesso!");
            history.push("/bodyshop");
            dispatch(setData({ reloadServiceType: !reloadServiceType }));
          } else {
            alertError("Erro ao remover, tente novamente mais tarde");
          }
        } else {
          alertError("Erro ao remover, tente novamente mais tarde");
        }
      } else {
        history.push("/bodyshop");
      }
    }
  };

  const closeModal = () => {
    if (
      compareValues(oldValues, values) ||
      compareValues(oldSubService, subService)
    ) {
      Swal.fire({
        title: "Deseja sair sem salvar?",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: "warning",
        text: "Ao sair, seus dados seram descartados.",
        denyButtonText: `Sair`,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isDenied) {
          history.push("/bodyshop");
        }
      });
    } else {
      history.push("/bodyshop");
    }
  };

  const onChange = (field, value) => {
    if (field === "percents") {
      setSubService((services) =>
        services?.map((item) =>
          item.id === value.id ? { ...item, ...value } : { ...item }
        )
      );
    } else {
      setValues((values) => ({ ...values, [field]: value }));
    }
  };

  const onSave = async () => {
    try {
      if (values && !values.title) {
        alertError("É obrigatório colocar um título");
        return;
      }

      if (subService.length <= 0 && !subService) {
        alertError("É obrigatório ter pelo menos um serviço adicionado");
        return;
      }

      const save = await saveServiceType(values.id, {
        title: values.title,
        subservice: JSON.stringify(subService),
        id_company: company.id,
      });
      alertSuccess("Sucesso ao salvar o/os subserviço/s!");
      history.push("/bodyshop");
      dispatch(setData({ reloadServiceType: !reloadServiceType }));
    } catch (error) {
      alertError("Erro ao salvar o serviço, tente novamente mais tarde.");
    }
  };

  return (
    <ModalServiceType
      compareValues={
        compareValues(oldValues, values) ||
        compareValues(oldSubService, subService)
      }
      closeModal={closeModal}
      onChange={onChange}
      values={values}
      isNew={isNew}
      onDeleteService={onDeleteService}
      onRemove={onRemove}
      serviceValues={servicesValues}
      subService={subService}
      addSubService={addSubService}
      setServiceValues={setServiceValues}
      onSave={onSave}
    />
  );
};

export default ModalServiceTypeContainer;
