import { supabase } from "../../supabaseClient";

export const saveBudgetServiceOrder = async (id = undefined, item) => {
  if (id) {
    return await supabase
      .from("BudgetServiceOrder")
      .update({ ...item })
      .match({ id })
      .select(
        `
        *,
        Vehicle(responsible, *)
        `
      );
  } else {
    return await supabase.from("BudgetServiceOrder").insert([{ ...item }]);
  }
};

export const deletedBudgetServiceOrder = async (id = undefined, user_id) => {
  if (id) {
    return await supabase
      .from("BudgetServiceOrder")
      .update({ deleted_at: new Date(), deleted_by: user_id })
      .eq("id", id);
  }
};
