import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { alertError, alertSuccess } from '../../components/Toast';
import { setData } from '../../core/redux/app/Actions';
import { saveAeService } from '../../core/utils/AeService';
import ModalPackAesthetic from '../../screens/ModalPackAesthetic/ModalPackAesthetic';
import { supabase } from '../../supabaseClient';
import { compareValues } from '../ModalClients/ModalClientsContainer';
import {
  totalHour,
  totalQnt,
  valuePerHour,
  valuePerQnt,
} from '../../core/utils/utils';
import { syncAePackServices } from '../../core/utils/AePackService';
import { useAestheticPackages } from '../../core/hooks/aesthetic_modules/useAestheticPacakges';

const ModalPackAestheticContainer = () => {
  const [serviceList, setServiceList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = location.pathname.split('/')[3];
  const params = useParams();
  const isNew = params.id === '0';
  const { loadData, oldValues, values, setValues } = useAestheticPackages({
    packageId: id,
  });

  const reloadAePackServices = useSelector(
    (state) => state.app.reloadAePackServices || false
  );
  const company = useSelector((state) => state.app.company || {});
  const user = useSelector((state) => state.app.user || {});

  useEffect(() => {
    if (id !== '0' && serviceList.length > 0) {
      loadData();
    }
  }, [id, serviceList]);

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    dispatch(
      setData({ [`compareValues_${path}`]: compareValues(oldValues, values) })
    );
  }, [values, oldValues]);

  useEffect(() => {
    loadAestheticBasicService();
  }, []);

  const loadAestheticBasicService = async () => {
    let { data, error } = await supabase
      .from('AestheticServices')
      .select('*')
      .eq('id_company', company.id)
      .eq('module_type', 1)
      .is('deleted_at', null);
    if (!error) {
      setServiceList(
        data.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
          p_time: item.p_time || '1',
        }))
      );
    }
  };

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: 'Deseja sair sem salvar?',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: 'warning',
        text: 'Ao sair, seus dados serão descartados.',
        denyButtonText: `Sair`,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isDenied) {
          history.push('/aesthetic');
        }
      });
    } else {
      history.push('/aesthetic');
    }
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onSave = async () => {
    try {
      const time = totalQnt(values.relatedServices);
      const value = valuePerQnt(values.relatedServices);
      if (values && !values.title) {
        alertError('Título é obrigatório.');
        return;
      }
      if (values && (!time || !value)) {
        alertError('Tempo ou valor para o serviço é obrigatório.');
        return;
      }
      const { data: service } = await saveAeService(values.id, {
        ...values,
        module_type: 2,
        id_company: company.id,
        p_time: time,
        p_value: values.finalValue || value * time,
      });
      await syncAePackServices(service[0].id, values.relatedServices);
      alertSuccess('Sucesso ao salvar o pacote de serviço!');
      history.push('/aesthetic');
      dispatch(setData({ reloadAePackServices: !reloadAePackServices }));
    } catch (error) {
      alertError(
        'Erro ao salvar o pacote de serviço, tente novamente mais tarde.'
      );
    }
  };

  const onRemove = async () => {
    const confirmation = await Swal.fire({
      title: 'Deseja mesmo excluir?',
      showCancelButton: true,
      showConfirmButton: false,
      showDenyButton: true,
      icon: 'warning',
      text: 'Seus dados serão completamente perdidos!',
      denyButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    });

    if (confirmation.isDenied) {
      if (values && values.id) {
        const { data, error } = await supabase
          .from('AestheticServices')
          .update({ deleted_at: new Date(), deleted_by: user.id })
          .eq('id', values.id);
        await supabase
          .from('PackAestheticServices')
          .delete()
          .eq('id_service_pack', values.id);

        if (!error) {
          alertSuccess('Removido com sucesso!');
          history.push('/aesthetic');
          dispatch(setData({ reloadAePackServices: !reloadAePackServices }));
        } else {
          alertError('Erro ao remover, tente novamente mais tarde');
        }
      } else {
        alertError('Erro ao remover, tente novamente mais tarde');
      }
    } else {
      history.push('/aesthetic');
    }
  };

  return (
    <ModalPackAesthetic
      compareValues={compareValues(oldValues, values)}
      closeModal={closeModal}
      onChange={onChange}
      values={values}
      setValues={setValues}
      isNew={isNew}
      onSave={onSave}
      onRemove={onRemove}
      serviceList={serviceList}
    />
  );
};

export default ModalPackAestheticContainer;
