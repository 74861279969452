import React, { useEffect, useState } from 'react';
import ModalService from '../../../ModalService/ModalService';
import Swal from 'sweetalert2';
import { alertError, alertSuccess } from '../../../../components/Toast';
import { supabase } from '../../../../supabaseClient';
import { compareValues } from '../../../../containers/ModalClients/ModalClientsContainer';
import { useDispatch, useSelector } from 'react-redux';
import { saveService } from '../../../../core/utils/service';

export const AddBodyshopService = ({
  isModalToCreateServiceOpen,
  setIsModalToCreateServiceOpen,
}) => {
  const dispatch = useDispatch();
  const [pieces, setPieces] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({ active: true });
  const company = useSelector((state) => state.app.company || {});

  useEffect(() => {
    loadPieces();
  }, [isModalToCreateServiceOpen]);

  const loadPieces = async () => {
    let { data, error } = await supabase
      .from('Part')
      .select('*')
      .eq('company', company.id)
      .is('deleted_at', null);
    if (!error) {
      setPieces(
        data.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
          parts: item.part,
        }))
      );
    }
  };

  useEffect(() => {
    if (values.pieces && values.service_type && values.service_type.title) {
      setValues((prevValues) => ({
        ...prevValues,
        title: `${values.pieces} (${values.service_type.title})`,
      }));
    }
  }, [values.pieces, values.service_type && values.service_type.title]);

  useEffect(() => {
    loadServiceType();
  }, []);

  const loadServiceType = async () => {
    let { data, error } = await supabase
      .from('ServiceTypes')
      .select('*')
      .eq('id_company', company.id)
      .is('deleted_at', null);
    if (!error) {
      setServiceType(
        data.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        }))
      );
    }
  };

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: 'Deseja sair sem salvar?',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: 'warning',
        text: 'Ao sair, seus dados seram descartados.',
        denyButtonText: `Sair`,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isDenied) {
          setIsModalToCreateServiceOpen(false);
        }
      });
    } else {
      setIsModalToCreateServiceOpen(false);
    }
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const parseFloatWithoutCurrencySymbolOrCommas = (value) => {
    if (value != null) {
      const numberString = value.toString().replace('R$', '').replace(',', '.');
      const number = parseFloat(numberString);
      return number;
    }
  };

  const onSave = async () => {
    const parsedPValue = parseFloatWithoutCurrencySymbolOrCommas(
      values.p_value
    );
    const parsedGValue = parseFloatWithoutCurrencySymbolOrCommas(
      values.g_value
    );
    const parsedMValue = parseFloatWithoutCurrencySymbolOrCommas(
      values.m_value
    );

    const updatedValues = {
      ...values,
      p_value: parsedPValue,
      g_value: parsedGValue,
      m_value: parsedMValue,
    };

    const extraProps = {};
    if (updatedValues && !updatedValues.title) {
      alertError('Título é obrigatório.');
      return;
    }

    if (updatedValues && !updatedValues.service_type) {
      alertError('Tipo de serviço é obrigatório.');
      return;
    }

    let { data, error } = await supabase
      .from('Services')
      .select('*')
      .eq('id_company', company.id)
      .eq('pieces', updatedValues?.pieces)
      .eq('service_type', updatedValues?.service_type?.value)
      .is('deleted_at', null);

    if (error) {
      throw new Error('Erro ao carregar');
    } else if (data && data.length > 0) {
      alertError(
        'Já existe um serviço com essa configuração de peça e tipo de serviço.'
      );
      return;
    }

    const save = await saveService(updatedValues.id, {
      ...updatedValues,
      ...extraProps,
      id_company: company.id,
      service_type: updatedValues?.service_type?.value,
    });
    if (!save.error) {
      alertSuccess('Sucesso ao salvar o serviço!');
      setIsModalToCreateServiceOpen(false);
      dispatch(setData({ reloadServices: !reloadServices }));
    } else {
      alertError('Erro ao salvar o serviço, tente novamente mais tarde.');
    }
  };

  useEffect(() => {
    setValues({});
  }, [isModalToCreateServiceOpen]);

  return (
    isModalToCreateServiceOpen && (
      <div className="container-modal-create-part">
        <ModalService
          pieces={filteredData(pieces)}
          compareValues={compareValues(oldValues, values)}
          closeModal={closeModal}
          onChange={onChange}
          values={values}
          serviceType={serviceType}
          isNew={true}
          onSave={onSave}
          mesh={false}
        />
      </div>
    )
  );
};

const filteredData = (data, search, parts) => {
  return data?.filter((item) => doFilter(item, search, parts));
};

const doFilter = (item, search, parts) => {
  let isSearch = true;
  if (search) {
    isSearch = item.name.toLowerCase().includes(search);
  }

  let isParts = true;
  if (
    parts &&
    parts.length > 0 &&
    parts?.filter((value) => value !== null)?.length > 0
  ) {
    isParts = item.part === parts?.find((value) => value === item?.part);
  }

  return isSearch && isParts;
};
