import React, { useState } from 'react';
import {
  MoneyInput,
  PercentageInput,
  SelectInput,
} from '../../../../components/Input/Input';
import { isMobile } from 'react-device-detect';
import ButtonSwitch from '../../../../components/Switch';
import GridTable from '@nadavshaar/react-grid-table';
import { columnsService, sizeOptions } from '../../utils/constants';
import { BUDGET_TABS } from '../../utils/enum';
import { LabelWithPlusButton } from '../LabelWithPlusButton';

export const BodyshopServices = (props) => {
  const {
    budget,
    values,
    onChange,
    isntLinearHour,
    hvTable,
    setIsntLinearHour,
    setOpen,
    bodyshopService,
    onRemoveService,
    changeValue,
  } = props;

  if (budget !== BUDGET_TABS.BODYSHOP) {
    return;
  }

  const columns = columnsService(
    onRemoveService,
    values,
    changeValue,
    budget
  ).map((column, index) => ({
    ...column,
    style: {
      backgroundColor: index % 2 === 0 ? 'gray !important' : 'white !important',
    },
  }));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      {isMobile ? (
        <div
          style={{
            display: 'flex',
            gap: '10px',
            paddingTop: '15px',
          }}
        >
          <div
            className="size-dropdown mobile-services-input"
            style={{ marginBottom: 5 }}
          >
            <div className="void-class">
              <SelectInput
                isSearchable={!isMobile}
                options={sizeOptions}
                placeholder="Selecione"
                defaultValue={
                  sizeOptions.find((item) => item.id === values?.size) ||
                  values?.size
                }
                value={sizeOptions.find((item) => item.id === values?.size)}
                onChange={(value) => onChange('size', value)}
                className="budget-percentage-input"
              />
            </div>
          </div>
          <PercentageInput
            placeholder={'%'}
            type="number"
            value={values?.percentage}
            onChange={(value) => onChange('percentage', value)}
            className="budget-details-margin mobile-services-input"
          />
          <div style={{ width: '100%' }}>
            {isntLinearHour ? (
              <div className="mobile-switch-div">
                <div className="void-class">
                  <SelectInput
                    // isSearchable={!isMobile}
                    options={hvTable}
                    label={''}
                    onChange={(value) => onChange('price', value)}
                    value={hvTable.find(
                      (item) =>
                        item.id == values?.price || item.id == values?.price?.id
                    )}
                    defaultValue={hvTable.find(
                      (item) =>
                        item.id == values?.price || item.id == values?.price?.id
                    )}
                    placeholder={'Selecione a tabela'}
                    className="budget-percentage-input"
                  />
                </div>
                <div className="mobile-switch-button">
                  <ButtonSwitch
                    value={values?.hour}
                    hasLabel={false}
                    defaultValue={values?.hour}
                    onChange={(value) => {
                      setIsntLinearHour(value);
                      onChange('hour', value);
                      onChange('price', 0);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <MoneyInput
                  type="number"
                  value={values?.price}
                  onChange={(value) => onChange('price', value)}
                  placeholder={'Preço'}
                  hasLabel={false}
                  className="budget-details-margin"
                />
                <div style={{ alignSelf: 'center' }}>
                  <ButtonSwitch
                    value={values?.hour}
                    hasLabel={false}
                    defaultValue={values?.hour}
                    onChange={(value) => {
                      setIsntLinearHour(value);
                      onChange('hour', value);
                      onChange('price', 0);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="budget-modal-main-div"
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          <div className="size-dropdown">
            <text
              style={{
                color: 'white',
                fontStyle: 'normal',
                fontFamily: 'poppins',
                fontSize: '14px',
                fontWeight: '500',
              }}
            >
              {' '}
              Tamanho{' '}
            </text>
            <div className="void-class">
              <SelectInput
                isSearchable={!isMobile}
                options={sizeOptions}
                placeholder="Selecione"
                defaultValue={
                  sizeOptions.find((item) => item.id === values?.size) ||
                  values?.size
                }
                value={sizeOptions.find((item) => item.id === values?.size)}
                onChange={(value) => onChange('size', value)}
              />
            </div>
          </div>
          <PercentageInput
            label={'Adicional s/ pintura'}
            placeholder={'%'}
            type="number"
            value={values?.percentage}
            onChange={(value) => onChange('percentage', value)}
            className="budget-percentage-input"
            classLabel="budget-label-input"
          />
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  marginBottom: 3,
                  alignItems: 'center',
                  gridGap: 10,
                }}
              >
                <text
                  style={{
                    color: 'white',
                    textAlign: 'end',
                    fontWeight: '600',
                  }}
                >
                  {' '}
                  Hora linear{' '}
                </text>
                <ButtonSwitch
                  value={values?.hour}
                  defaultValue={values?.hour}
                  hasLabel={false}
                  onChange={(value) => {
                    setIsntLinearHour(value);
                    onChange('hour', value);
                    onChange('price', 0);
                  }}
                />
                <text style={{ color: 'white', fontWeight: '600' }}>
                  {' '}
                  Hora tabela{' '}
                </text>
              </div>
            </div>
            {isntLinearHour ? (
              <div className="budget-modal-main-div">
                <div className="void-class">
                  <SelectInput
                    options={hvTable}
                    // isSearchable={!isMobile}
                    label={''}
                    onChange={(value) => onChange('price', value)}
                    value={hvTable.find(
                      (item) =>
                        item.id == values?.price || item.id == values?.price?.id
                    )}
                    defaultValue={hvTable.find(
                      (item) =>
                        item.id == values?.price || item.id == values?.price?.id
                    )}
                    placeholder={'Selecione a tabela'}
                    className="budget-percentage-input"
                  />
                </div>
              </div>
            ) : (
              <MoneyInput
                type="number"
                value={values?.price}
                onChange={(value) => onChange('price', value)}
                placeholder={'Preço'}
                hasLabel={false}
                className="budget-percentage-input"
              />
            )}
          </div>
        </div>
      )}

      <LabelWithPlusButton
        setOpen={setOpen}
        tabNumber={1}
        label={'Serviços Funilaria e Pintura'}
      />
      <div className="bodyshop-body modal-budget modal-budget-gridtable service-gridtable">
        <GridTable
          columns={columns}
          rows={bodyshopService}
          enableColumnsReorder={false}
          showSearch={false}
          canReorder={false}
          isPaginated={false}
          showColumnVisibilityManager={false}
          showRowsInformation={false}
          texts={{ noResults: 'Nenhum resultado foi encontrado' }}
        />
      </div>
    </div>
  );
};
