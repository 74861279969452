import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { setData } from "../../core/redux/app/Actions";
import { supabase } from "../../supabaseClient";
import { compareValues } from "../ModalClients/ModalClientsContainer";
import { saveProductionPhase } from "../../core/utils/ProductionPhase";
import Swal from "sweetalert2";
import ModalProductionPhase from "../../screens/ModalProductionPhase/ModalProductionPhase";
import { alertError, alertSuccess } from "../../components/Toast";

const ModalProductionPhaseContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const params = useParams();
  const isNew = params.id === "0";
  const reloadProductionPhase = useSelector(
    (state) => state.app.reloadProductionPhase || false
  );
  const company = useSelector((state) => state.app.company || {});
  const user = useSelector((state) => state.app.user || {});
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});
  const [subService, setSubService] = useState([]);
  const [oldSubService, setOldSubService] = useState([]);
  const [auxValues, setAuxValues] = useState({});

  const loadData = async () => {
    let { data, error } = await supabase
      .from("ProcSteps")
      .select("*")
      .eq("id", id)
      .is("deleted_at", null);

    if (!error) {
      setValues(data[0]);
      setOldValues(data[0]);
      const subService = JSON.parse(data[0].items);
      setSubService(subService);
      setOldSubService(subService);
    }
  };

  useEffect(() => {
    if (id !== "0") {
      loadData();
    }
  }, [id]);

  const addChecklistItem = () => {
    if (auxValues && auxValues.description) {
      setSubService((values) =>
        values.concat([{ ...auxValues, id: Math.random() }])
      );
      setAuxValues({ description: "" });
    }
  };

  const onDeleteChecklistItem = (item) => {
    setSubService((values) => values.filter((row) => row.id !== item.id));
  };

  const closeModal = () => {
    if (
      compareValues(oldValues, values) ||
      compareValues(oldSubService, subService)
    ) {
      Swal.fire({
        title: "Deseja sair sem salvar?",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: "warning",
        text: "Ao sair, seus dados serão descartados.",
        denyButtonText: `Sair`,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isDenied) {
          history.push("/bodyshop");
        }
      });
    } else {
      history.push("/bodyshop");
    }
  };

  const onChange = (field, value) => {
    if (field == "number") {
      setSubService((services) =>
        services?.map((item) =>
          item.id === value.id ? { ...item, ...value } : { ...item }
        )
      );
    } else {
      setValues((values) => ({ ...values, [field]: value }));
    }
  };

  const onSave = async () => {
    try {
      if (values && !values.title) {
        alertError("É obrigatório colocar um título");
        return;
      }

      if (subService.length <= 0 && !subService) {
        alertError("É obrigatório ter pelo menos um item da lista adicionado");
        return;
      }

      const save = await saveProductionPhase(values.id, {
        title: values.title,
        index: values.index,
        items: JSON.stringify(subService),
        id_company: company.id,
      });
      alertSuccess("Sucesso ao salvar a lista!");
      history.push("/bodyshop");
      dispatch(setData({ reloadProductionPhase: !reloadProductionPhase }));
    } catch (error) {
      alertError("Erro ao salvar a lista, tente novamente mais tarde.");
    }
  };

  const onRemove = async () => {
    const confirmation = await Swal.fire({
      title: "Deseja mesmo excluir?",
      showCancelButton: true,
      showConfirmButton: false,
      showDenyButton: true,
      icon: "warning",
      text: "Seus dados serão completamente perdidos!",
      denyButtonText: "Excluir",
      cancelButtonText: "Cancelar",
    });

    if (confirmation.isDenied) {
      if (values && values.id) {
        const { data, error } = await supabase
          .from("ProcSteps")
          .update({ deleted_at: new Date(), deleted_by: user.id })
          .eq("id", values.id);

        if (!error) {
          alertSuccess("Removido com sucesso!");
          history.push("/bodyshop");
          dispatch(setData({ reloadProductionPhase: !reloadProductionPhase }));
        } else {
          alertError("Erro ao remover, tente novamente mais tarde");
        }
      } else {
        alertError("Erro ao remover, tente novamente mais tarde");
      }
    } else {
      history.push("/bodyshop");
    }
  };

  return (
    <ModalProductionPhase
      compareValues={
        compareValues(oldValues, values) ||
        compareValues(oldSubService, subService)
      }
      closeModal={closeModal}
      onChange={onChange}
      values={values}
      isNew={isNew}
      onDeleteChecklistItem={onDeleteChecklistItem}
      addChecklistItem={addChecklistItem}
      subService={subService}
      setValues={setValues}
      onSave={onSave}
      setAuxValues={setAuxValues}
      auxValues={auxValues}
      onRemove={onRemove}
    />
  );
};

export default ModalProductionPhaseContainer;
